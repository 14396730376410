import React from "react";

import { IControlsProps } from "@/interfaces/Controls/Controls";
import CustomIconifyIcon from "@/components/CustomIconifyIcon/CustomIconifyIcon";

const Controls: React.FC<IControlsProps> = ({ name, onClick }) => {
  const renderControl = () => {
    switch (name.toLowerCase()) {
      case "left":
        return (
          <div
            className="bg-black/[0.4] px-1.375 py-4 md:px-7 md:py-6 rounded-lg cursor-pointer flex items-center justify-center"
            onClick={onClick}
          >
            <CustomIconifyIcon
              name={"fluent-mdl2:chevron-left-small"}
              className="text-white w-2.5 md:w-5 h-2.5 md:h-5"
            />
          </div>
        );
      case "right":
        return (
          <div
            className="bg-black/[0.4] px-1.375 py-4 md:px-7 md:py-6 rounded-lg cursor-pointer flex items-center justify-center"
            onClick={onClick}
          >
            <CustomIconifyIcon
              name={"fluent-mdl2:chevron-right-small"}
              className="text-white w-2.5 md:w-5 h-2.5 md:h-5"
            />
          </div>
        );
      // case "close":
      //   return (
      //     <div
      //       className="bg-black/[0.4] rounded-full cursor-pointer w-16 flex items-center justify-center"
      //       onClick={onClick}
      //     >
      //       <CustomIconifyIcon name={"ion:close-sharp"} className="text-white" />
      //     </div>
      //   );
      default:
        return null;
    }
  };

  return renderControl();
};

export default Controls;
