import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_react-dom@18._g5otwj6zqujlnvffirl7pzkkvy/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_react-dom@18._g5otwj6zqujlnvffirl7pzkkvy/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/Image/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/useQuerySubscription/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/useSiteSearch/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-datocms@6.0.5_@types+react-dom@18.3.0_@types+react@18.3.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-datocms/dist/esm/VideoPlayer/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CustomIconifyIcon/CustomIconifyIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/SplideCarousel/SplideCarousel.tsx");
