"use client";
import React, { FC, useState } from "react";
import clsx from "clsx";
import Image from "next/image";
import { SRCImage as DatoImage } from "react-datocms";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

// eslint-disable-next-line import/no-unassigned-import
import "@splidejs/react-splide/css/core";

import Typography from "@/components/Typography/Typography";
import Controls from "@/components/Controls/Controls";

import { ICarouselProps } from "@/interfaces/Carousel/Carousel";

const SplideCarousel: FC<ICarouselProps> = ({
  overlayBreadcrumb,
  overlayTitle,
  overlaySubtitle,
  carouselItems: originalCarouselItems,
  className,
  startIndex,
  isMainCarousel,
  isHomePage,
}) => {
  const [currentIndex] = useState<any>(startIndex ? startIndex : 0);
  const carouselItems = [
    ...originalCarouselItems.slice(currentIndex),
    ...originalCarouselItems.slice(0, currentIndex),
  ];
  const carouselClassName: string = clsx("relative", className);

  const modifiedOverlayTitle =
    typeof overlayTitle === "string" && isHomePage
      ? overlayTitle.replace(/^(\w+)\s/, "$1<br>")
      : overlayTitle;

  return (
    <div className={carouselClassName}>
      <Splide
        aria-label="images"
        hasTrack={false}
        className="h-full"
        options={{
          type: "fade",
          rewind: true,
          autoplay: true,
          interval: 3000,
          speed: 1200,
          arrows: carouselItems.length < 2 ? false : true,
        }}
      >
        <SplideTrack className="h-full">
          {carouselItems.map((item, idx) =>
            item.responsiveImage ? (
              <SplideSlide key={item.id}>
                <div className="relative h-full">
                  <DatoImage
                    data={item.responsiveImage}
                    // className="relative"
                    // objectFit="cover"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      height: "100%",
                      maxWidth: "100%",
                    }}
                    // layout="fill"
                    sizes="100vw"
                    srcSetCandidates={[0.25, 0.5, 0.75, 1]}
                    priority={isMainCarousel && idx === 0}
                  />
                </div>
              </SplideSlide>
            ) : (
              <SplideSlide key={item.id}>
                <Image
                  className="!relative"
                  src={item.url}
                  alt={item.alt}
                  fill={true}
                  style={{ objectFit: "contain" }}
                />
              </SplideSlide>
            ),
          )}
        </SplideTrack>

        <div className="absolute top-1/2 right-2 transform -translate-y-1/2 splide__arrows z-10">
          <div className="m-2 splide__arrow--next" style={{ userSelect: "none" }}>
            <Controls name="right" />
          </div>
          <div className="m-2 splide__arrow--prev" style={{ userSelect: "none" }}>
            <Controls name="left" />
          </div>
        </div>
      </Splide>
      {overlayTitle && overlaySubtitle && (
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-45">
          <div className="absolute px-6 left-2.5 right-2.5 bottom-16 cmd:bottom-8.625 md:left-40 md:right-40 flex flex-col justify-center items-center gap-4 ">
            <Typography
              variant="breadcrumbs-normal"
              className="text-white/60 font-semibold tracking-widest uppercase"
            >
              {overlayBreadcrumb}
            </Typography>
            <Typography
              variant="hero-title"
              isHomePage={isHomePage}
              className="text-white !text-center splide-carousel-overlay-title"
            >
              {modifiedOverlayTitle}
            </Typography>
            <Typography
              variant="hero-subtitle"
              className="text-white text-center splide-carousel-overlay-subtitle w-18.75 cxs:w-33.5625 cmd:w-54.875 break-words"
            >
              {overlaySubtitle}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default SplideCarousel;
